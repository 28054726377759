<template>
  <div class="page_comsxlisttable" style="width: 100%;height: 100%;">
    <el-dialog :title="dialogTitle" :append-to-body="true" :visible.sync="visiable" width="1000px"
               custom-class="cus_dialog" @close="onClose">
      <el-row style="margin-bottom: 10px;" class="">
        <div class="">
          <el-input v-model="searchForm.mohuTitle" clearable style="width: 300px!important;"
                    placeholder="模糊搜索">
            <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
          </el-input>
        </div>
      </el-row>

      <el-table :data="DataList" height="calc( 100% - 100px)"
                style="width: 100%;" stripe border>
        <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
        <el-table-column prop="title" label="名称" :show-overflow-tooltip="true" width="200" align="center">
          <template slot-scope="scope">
            <div style="color: #409EFF;cursor: pointer;">{{ scope.row.title }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="typename" label="分类" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.typename2 }} {{ scope.row.typename1 }}
          </template>
        </el-table-column> -->
        <el-table-column prop="storename" label="所在仓库" :show-overflow-tooltip="true" min-width="120" align="center"/>
        <!-- <el-table-column prop="jldw" label="单位" align="center"/> -->
        <el-table-column prop="pinpai" label="品牌" :show-overflow-tooltip="true" min-width="85" align="center"/>
        <el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true" min-width="85" align="center"/>

        <el-table-column prop="yujing_num" label="预警数量" :show-overflow-tooltip="true" min-width="85" align="center">
          <template slot="header">
            <span style="color: #F56C6C">预警数量</span>
          </template>
          <template slot-scope="scope">
            <span style="color: #F56C6C">{{ scope.row.yujing_num }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="store_num" label="剩余库存" :show-overflow-tooltip="true" min-width="85" align="center">
          <template slot="header">
            <span style="color: #F56C6C">剩余库存</span>
          </template>
          <template slot-scope="scope">
            <span style="color: #f00">{{ scope.row.store_num }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="jine" label="单价（加权平均值）" width="130" :show-overflow-tooltip="true">
          <template slot="header">
            <div style="text-align: center;">单价</div>
            <div style="text-align: center;">（加权平均值）</div>
          </template>
          <template slot-scope="scope">
            <div class="flexCenter">￥{{ scope.row.jine }}</div>
          </template>
        </el-table-column> -->
        <el-table-column prop="total" label="总价(元)" :show-overflow-tooltip="true" width="85" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.total }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="cus_page"
                     @size-change="handleSizeChange"
                     @current-change="paginate"
                     :current-page.sync="page.current_page"
                     :page-sizes="[10, 50, 100]"
                     :page-size="page.per_page"
                     layout="total,sizes, prev, pager, next"
                     :total="page.count">
      </el-pagination>

      <div slot="footer">
        <el-button type="primary" @click="onClose" size="small" plain>关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  components: {},
  props:['dialogVisible_yujing','dialogTitle'],
  data() {
    return {
      searchForm: {},
      DataList: [],
      multipleSelection: [],
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      visiable:false,
    }
  },
  mounted() {
    this.getList();
    this.visiable =this.dialogVisible_yujing
  },
  methods: {
    getList() {
      let _this = this
      _this.DataList = []
      let params = {}, arr = []
      params.mohuTitle = _this.searchForm.mohuTitle
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.yujing = 1
      this.$http.post("/api/sx_hc_list", params).then(res => {
        if(res.data.data){
          res.data.data.map(a=>{
            if(a.jine){
              a.jine =  parseFloat(a.jine).toFixed(2)
            }else {
              a.jine = 0
            }
            if (a.jine && a.store_num) {
              a.total = this.$mul(a.jine, a.store_num)
              a.totalwan = this.$div(a.total,10000)
            }else {
              a.total = 0
              a.totalwan = 0
            }
          })
          this.DataList = res.data.data;
        }
        if (res.data && res.data.page) {
          this.page = res.data.page
          this.page_hc = res.data.page
        }
      })
    },

    onSearch() {
      this.page.current_page = 1
      this.getList()
    },

    onClose(){
      this.visiable = false
      this.$$parent(this,'closeYujing')
    },
    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.per_page = val
      this.getList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },
  }
}
</script>

<style lang="less" type="text/less">
.page_comsxlisttable {
  .el-table__expanded-cell {
    padding: 8px 8px 6px 68px;
  }

  .demo-table-expand {
    font-size: 14px;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .special .el-form-item{
    width: 100% !important;

  }
}
</style>